
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import Layout, {  } from '/vercel/path0/layouts/index'
import BlogPreview from 'components/blog-preview'
import Projects from 'components/projects'
import Image from 'next/image'
export const frontMatter = {
  __resourcePath: 'index.mdx',
  __scans: {},
  slug: 'index',
  readingTime: {
    text: '1 min read',
    minutes: 0.07,
    time: 4200,
    words: 14
  },
  layout: 'index'
};

const layoutProps = {
  frontMatter
};
const MDXLayout = Layout
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Projects mdxType="Projects" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;