import Grid from '@mui/material/Grid'
import React, {useEffect, useState} from 'react'

const Banner = ({
  bannerDataJson,
}: // setIndex,
{
  // heading: string
  // subHeading: string
  // imageUrl: string
  // buttonText: string
  index: number
  submitCallback?: () => void
  bannerDataJson: any
  // setIndex?: (prevIndex: any) => void
}) => {
  const [open, setOpen] = useState(false)
  const [index, setIndex] = useState(0)
  const [banner, setBanner] = useState(bannerDataJson ? bannerDataJson[index] : undefined)

  useEffect(() => {
    setTimeout(() => {
      setOpen(true)
    }, 15000)
  }, [])
  useEffect(() => {
    setBanner(bannerDataJson ? bannerDataJson[index] : null)
  }, [index])

  useEffect(() => {
    let interval: any = null
    if (bannerDataJson?.length > 1) {
      interval = setTimeout(() => {
        setIndex((prevIndex) => {
          return prevIndex === bannerDataJson?.length - 1 ? 0 : prevIndex + 1
        })
      }, banner?.duration)
    }
    return () => clearTimeout(interval)
  }, [banner, bannerDataJson?.length])

  return (
    <Grid container className="banner-wrapper border" onClick={() => window.open(banner?.affilatedUrl, '_blank')}>
      <Grid container xs={12} sm={12} md={12}>
        <Grid item xs={12} sm={12} md={6}>
          <img src={'/images/' + banner?.imageUrl} alt="credit logo" className="banner-img lineUp" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} className="px-2">
          <h2 className="lineUp">{banner?.heading}</h2>
          <p className="lineUp">{banner?.subHeading}</p>
          <button className="w-100 px-2 ">
            <p className="lineUp cta border-4">{banner?.buttonText}</p>
          </button>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className="flex"></Grid>
    </Grid>
  )
}

export default Banner
