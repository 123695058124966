import React, {useState, useEffect} from 'react'
import Banner from '.'
import {bannerDataJson} from '../../data/bannersData/bannerData'

interface BannerPopupProps {
  index?: number
}

const BannerWrapper: React.FC<BannerPopupProps> = ({index = 0}) => {
  const [open, setOpen] = useState(false)
  const [bannerIndex, setBannerIndex] = useState(index)
  const [banner, setBanner] = useState(bannerDataJson[bannerIndex])

  useEffect(() => {
    setTimeout(() => {
      setOpen(true)
    }, 15000)
  }, [])
  useEffect(() => {
    setBanner(bannerDataJson[bannerIndex])
  }, [bannerIndex])

  useEffect(() => {
    let interval: any = null
    if (bannerDataJson.length > 1) {
      interval = setTimeout(() => {
        setBannerIndex((prevIndex) => {
          return prevIndex === bannerDataJson.length - 1 ? 0 : prevIndex + 1
        })
      }, banner.duration)
    }
    return () => clearTimeout(interval)
  }, [banner, bannerDataJson.length])

  return (
    <>
      <Banner bannerDataJson={bannerDataJson} index={bannerIndex} />
    </>
  )
}

export default BannerWrapper
